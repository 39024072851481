/* Positions
======================= */
.absolute {
    position: absolute;
}

.relative {
    position: relative;
}
/* *********************** */

/* Displays
======================= */
.dtable-full {
    display: table;
    width: 100%;
}

.inline {
    display: inline;
}

.inline-block {
    display: inline-block;
}

.inline-block-full {
    display: inline-block;
    width: 100%;
}

.block {
    display: block;
}
/* *********************** */

/* Margin
======================= */
.margin-t0 {
    margin-top: 0;
}

.margin-t10 {
    margin-top: 10px;
}

.margin-r10 {
    margin-right: 10px;
}

.margin-b0 {
    margin-bottom: 0;
}

.margin-b10 {
    margin-bottom: 10px;
}

.margin-l10 {
    margin-left: 10px;
}

.margin-t20 {
    margin-top: 20px;
}

.margin-r20 {
    margin-right: 20px;
}

.margin-b20 {
    margin-bottom: 20px;
}

.margin-l20 {
    margin-left: 20px;
}

.margin-t40 {
    margin-top: 40px;
}

.margin-r40 {
    margin-right: 40px;
}

.margin-b40 {
    margin-bottom: 40px;
}

.margin-l40 {
    margin-left: 40px;
}
/* *********************** */

/* Padding
======================= */
.padding-t0 {
    padding-top: 0 !important;
}

.padding-t10 {
    padding-top: 10px;
}

.padding-r0 {
    padding-right: 0;
}

.padding-r10 {
    padding-right: 10px;
}

.padding-b0 {
    padding-bottom: 0;
}

.padding-b10 {
    padding-bottom: 10px;
}

.padding-l10 {
    padding-left: 10px;
}

.padding-l0 {
    padding-left: 0;
}

.padding-t20 {
    padding-top: 20px;
}

.padding-r20 {
    padding-right: 20px;
}

.padding-b20 {
    padding-bottom: 20px;
}

.padding-l20 {
    padding-left: 20px;
}

.padding-t40 {
    padding-top: 40px;
}

.padding-r40 {
    padding-right: 40px;
}

.padding-b40 {
    padding-bottom: 40px;
}

.padding-l40 {
    padding-left: 40px;
}
/* *********************** */

/* Sections Padding
======================= */
.section-mini {
    min-height: 300px;
    padding: 20px 0;
}

.section-small {
    padding: 40px 0;
}

.section-medium {
    padding: 60px 0;
}

.section-large {
    padding: 80px 0;
}
/* *********************** */

.col-panel {
    background: #fff;
    box-shadow: 0 3px 28px -20px #000;
    padding: 15px;
    border-radius: 8px;
}