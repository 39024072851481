#breadcrumb {
    background: #0c45af;
    border-top: 6px solid #EAEAEA;
    padding: 10px 0;
    position: relative;
    top: -5px;
}

#breadcrumb .breadcrumb {
    background: none;
    margin: 0;
    padding: 0;
    text-align: center;
}

#breadcrumb li a, #breadcrumb li a:focus {
    color: #fff;
    line-height: 40px;
}

#breadcrumb li.active, #breadcrumb li.active {
    color: #a9d1fd;
}

#breadcrumb li a:hover {
    color: #a9d1fd;
}