.post-pagination {
    display: inline;
    float: left;
    text-align: center;
    width: 100%
}

.post-pagination .pagination {
    background: #e6e6e6;
    border: 1px solid #d2cdcd;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5) inset;
    -moz-box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5) inset;
    -webkit-box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5) inset;
    overflow: hidden;
    padding: 10px;
}

.post-pagination .pagination li a, .post-pagination .pagination li span {
    background: #f0f0f0;
    border: 1px solid #c2bebe;
    border-radius: 4px;
    color: #1E2024;
    margin: 0 3px;
}

.post-pagination .pagination li a:hover, .post-pagination .pagination li span:hover {
    background-color: #fff;
}

.post-pagination .pagination li a:focus, .post-pagination .pagination li span:focus {
    color: #00993f;
}

.post-pagination .pagination li.active span, .post-pagination .pagination li.disabled span {
    transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
}

.post-pagination .pagination li.active span {
    background: #00993f;
    border-color: #00993f;
    color: #fff;
}

.post-pagination .pagination li.active span:hover {
    background: #fff;
    color: #040508 !important
}