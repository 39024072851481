#checkout .table-grid .grid-header {
    border: 1px solid #ddd;
    border-bottom-width: 0;
    display: table;
    width: 100%;
}

#checkout .table-grid .grid-item:first-child {
    border-left-width: 1px;
}

#checkout .table-grid .grid-body {
    border-bottom: 1px solid #ddd;
}

#checkout .table-grid.table-fix-height .grid-item {
    min-height: 116px;
}

#checkout .table-grid.table-fix-height .grid-header .grid-item {
    min-height: initial;
}

#checkout .table-grid .dtable-full {
    border: 1px solid #ddd;
    border-bottom-width: 0;
}

#checkout .table-grid .grid-item:first-child {
    border-left: 0;
}

#checkout .table-grid .grid-item {
    border-left: 1px solid #ddd;
    padding: 15px;
}

#checkout .table-grid .grid-item figure {
    border: 1px solid #ddd;
    border-radius: 4px;
    display: inline-block;
    max-height: 80px;
    max-width: 80px;
    overflow: hidden;
    padding: 5px;
}

#checkout .table-grid .grid-item h5 {
    margin: 0 0 0 10px;
    vertical-align: top;
}

#checkout .alert-reservation {
    background-color: #FFF;
    border: 1px solid #f3857d;
    color: #f3857d;
    float: left;
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
}

#checkout .table-checkout .group-shipping input {
    border-radius: 0;
    display: inline-block;
    height: 31px;
    width: calc(100% - 95px);
}

#checkout .table-checkout .group-shipping button {
    border-radius: 0;
    padding: 5px 15px;
}