.cl-warning, a.cl-warning, a.cl-warning:focus {
    color: #ff9d3c;
}

.cl-neutral, a.cl-neutral, a.cl-neutral:focus {
    color: #A3A3A3;
}

.cl-success-light, a.cl-success-light, a.cl-success-light:focus {
    color: #79CE3D;
}

.cl-success-medium, a.cl-success-medium, a.cl-success-medium:focus {
    color: #01AC66;
}

.cl-success, a.cl-success, a.cl-success:focus {
    color: #167A62;
}

.cl-white, a.cl-white, a.cl-white:focus {
    color: #fff;
}

.cl-black, a.cl-black, a.cl-black:focus {
    color: #000;
}

.cl-default, a.cl-default, a.cl-default:focus {
    color: #CBCBCB;
}

.cl-primary, a.cl-primary, a.cl-primary:focus {
    color: #037635;
}

.cl-secundary, a.cl-secundary, a.cl-secundary:focus {
    color: #B31D1C;
}

.cl-yellow {
    color: #ffe670;
}

/* Hover
======================= */
.cl-hover-warning:hover, a.cl-hover-warning:hover {
    color: #ff9d3c;
}

.cl-hover-neutral:hover, a.cl-hover-neutral:hover {
    color: #A3A3A3;
}

.cl-hover-success-light:hover, a.cl-hover-success-light:hover {
    color: #79CE3D;
}

.cl-hover-success-medium:hover, a.cl-hover-success-medium:hover {
    color: #01AC66;
}

.cl-hover-success:hover, a.cl-hover-success:hover {
    color: #167A62;
}

.cl-hover-white:hover, a.cl-hover-white:hover {
    color: #fff;
}

.cl-hover-black:hover, a.cl-hover-black:hover {
    color: #1c1c1c;
}

.cl-hover-default:hover, a.cl-hover-default:hover {
    color: #CBCBCB;
}

.cl-hover-primary:hover, a.cl-hover-primary:hover {
    color: #037635;
}

.cl-hover-secundary:hover, a.cl-hover-secundary:hover {
    color: #B31D1C;
}

.cl-blue-marin{
    color: #465363;
}

.cl-red{
    color: #f44e4e;
}

.cl-orange{
    color: #feb811;
}

.cl-blue{
    color: #2c68f2;
}

.cl-green{
    color: #1bb026;
}