.sec-contacts .form-box {
    background: #fff;
    box-shadow: 0 3px 28px -20px #000;
    padding: 0px 60px;
}

.sec-contacts .form-box form input, .sec-contacts .form-box form textarea {
    background: #F8F9FB;
    color: #bbbcbf;
    font-size: 16px;
}

.sec-contacts .form-box form .btn {
    border-radius: 0;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.2em;
    padding: 8px 12px;
}
