.section-banner{
    background: url(../../images/bg-top.jpg) center top no-repeat;
    background-size: cover;
    padding-top: 120px;
}

.section-banner .margin-top-1{
    min-height: 15vw;
}

.section-banner .col-nav{
    padding-top:  7vw;
}

.section-banner .col-nav ul{
    padding-left: 0vw;
}

.section-banner .col-nav ul li{
    position: relative;
    line-height: 50px;
    width: 100%;
    padding-left: 50px;
}

.section-banner .col-nav ul li:before{
    content: '';
    background: url(../../images/banner-mais.png) left top no-repeat;
    position: absolute;
    left: 0;
    top: 50%;
    height: 27px;
    width: 27px;
    transform: translateY(-50%);
}

@media (max-width: 991px) {
    .section-banner .col-nav ul li {
        line-height: 22px;
        margin-bottom: 27px;
    }

}

@media (max-width: 500px) {
    .section-banner {
        text-align: center;
    }

    .section-banner img {
        display: inline-block;
    }

    .section-banner .col-phone {
        display: none;
    }
}
