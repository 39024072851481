#client-panel hr {
    border-top-color: #dedede;
}

#client-panel .well {
    background: #ececec;
}

#client-panel .box-client-menu {
    background: #f5f5f5;
}

#client-panel .box-client-menu h5 {
    margin: 0;
    padding: 15px 20px;
}

#client-panel .box-client-menu ul {
    margin: 0;
}

#client-panel .box-client-menu li {
    border-top: 2px solid #fff;
}

#client-panel .box-client-menu ul a {
    padding: 12px 20px;
}

#client-panel .box-client-menu ul a.active {
    background: #1CAC99;
    color: #fff;
}

#client-panel .box-client-menu ul a:hover {
    box-shadow: 6px 0 0 #1CAC99 inset;
}

#client-panel .box-client-info {
    background: #f5f5f5;
    border: 1px solid #e0e0e0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 15px;
}

#client-panel .box-client-addresses .box-address, #client-panel .box-client-addresses .box-new-address {
    background: #f5f5f5;
    border: 1px solid #e0e0e0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    padding: 15px;
}

#client-panel .box-client-addresses .box-address p {
    min-height: 100px;
}

#client-panel .box-client-addresses .box-new-address {
}

#client-panel .box-client-addresses .box-new-address a {
    display: inline-block;
    padding: 20px 0;
    width: 100%;
}

#client-panel .box-client-addresses .box-new-address .fa {
    border-radius: 50%;
    box-shadow: 0 0 0 17px #1cac99 inset;
    color: #1CAC99;
    font-size: 45px;
    height: 70px;
    margin: 10px 0;
    padding: 13px;
    transition: transform 0.2s ease-in-out;
    -moz-transition: transform 0.2s ease-in-out;
    -o-transition: transform 0.2s ease-in-out;
    -webkit-transition: transform 0.2s ease-in-out;
    width: 70px;
}

#client-panel .box-client-addresses .box-new-address a:hover .fa {
    box-shadow: 0 0 0 17px #23d6be inset;
    color: #23d6be;
    transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    -webkit-transform: scale(1.1);
}
