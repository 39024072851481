.products-container .product-item {
    background-color: #fff;
    border: 1px solid #e4e7ed;
    border-radius: 2px;
    margin-bottom: 15px;
}

.products-container .product-item > figure {
    float: none;
    margin: 0;
    max-height: 287.5px;
    min-height: 287.5px;
    overflow: hidden;
    position: relative;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    width: 100%;
}

.products-container figure .product-shadow {
    background: rgba(0, 0, 0, 0.8);
    height: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    width: 100%;
}

.products-container figure .see-more, .products-container figure .cart-add, .products-container figure .budget-request {
    color: #fff;
    display: inline-block;
    font-weight: bold;
    height: auto;
    max-width: 222px;
    opacity: 1;
    padding: 10px;
    position: absolute;
    right: calc(50% - 111px);
    text-align: center;
    text-transform: uppercase;
    width: calc(100% - 20px);
}

.products-container figure .see-more {
    background: rgba(91, 192, 222, 0.5);
    border: 1px solid #46b8da;
    top: 84px;
    transform: translateX(0) translateY(-84px);
    -moz-transform: translateX(0) translateY(-84px);
    -ms-transform: translateX(0) translateY(-84px);
    -o-transform: translateX(0) translateY(-84px);
    -webkit-transform: translateX(0) translateY(-84px);
}

.products-container figure .cart-add {
    background: rgba(52, 177, 52, 0.5);
    border: 1px solid #4cae4c;
    top: 136px;
    transform: translateX(0) translateY(81px);
    -moz-transform: translateX(0) translateY(81px);
    -ms-transform: translateX(0) translateY(81px);
    -o-transform: translateX(0) translateY(81px);
    -webkit-transform: translateX(0) translateY(81px);
}

.products-container figure .budget-request {
    background: rgba(236, 136, 24, 0.8);
    border: 1px solid rgba(255, 157, 34, 0.8);
    top: 136px;
    transform: translateX(0) translateY(81px);
    -moz-transform: translateX(0) translateY(81px);
    -ms-transform: translateX(0) translateY(81px);
    -o-transform: translateX(0) translateY(81px);
    -webkit-transform: translateX(0) translateY(81px);
}

.products-container .product-item:hover .see-more, .products-container .product-item:hover .cart-add, .products-container .product-item:hover .budget-request {
    opacity: 1;
    transform: translateX(0) translateY(0);
    -moz-transform: translateX(0) translateY(0);
    -ms-transform: translateX(0) translateY(0);
    -o-transform: translateX(0) translateY(0);
    -webkit-transform: translateX(0) translateY(0);
}

.products-container figure .see-more:hover {
    background: rgba(91, 192, 222, 0.8);
}

.products-container figure .cart-add:hover {
    background: rgba(52, 177, 52, 0.8);
}

.products-container figure .budget-request:hover {
    background: rgba(236, 136, 24, 0.91);
}

.products-container .product-item:hover figure .product-shadow {
    opacity: 1;
}

.products-container .product-item > figure img {
    display: inline-block;
    height: 286.5px;
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    -moz-transition: transform 0.5s ease;
    -o-transition: transform 0.5s ease;
    -webkit-transition: -webkit-transform 0.5s ease;
    -webkit-transition: transform 0.5s ease;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    object-fit: contain;
}

.products-container .product-item > figure img:hover {
    transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    -webkit-transform: scale(1.1);
}

.products-container .product-item_info {
    padding: 0 15px;
}

.products-container .product-item_info h5 {
    font-size: 13px;
    font-weight: normal;
}

.products-container .product-item_info p {
    color: #7f92a0;
}

.products-container .cback-info {
    background: #D5C69F;
    color: #fff;
    padding: 2px 0;
}

.products-container .cback-info span {
    letter-spacing: -0.5px;
}

.products-container .cback-info .fa {
    color: #FCD73E;
}

.products-container .price-info {
    padding: 0 15px 15px;
    line-height: 20px;
}

.products-container .price-info small {
    opacity: 0.5;
}

.products-container .price-info p {
    margin: 0;
    font-weight: bold;
    font-size: 20px;
    color: #5fa80b;
}

.products-container .price-info span {
    font-size: 13px;
}