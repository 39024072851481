/* Fonts
======================= */
.montserrat {
    font-family: 'montserratregular', sans-serif;
}

.lato {
    font-family: "Lato", sans-serif;
}

@font-face {
    font-family: "Flaticon2";
    src: url("../fonts/Flaticon.eot");
    src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"), url("../fonts/Flaticon.woff") format("woff"), url("../fonts/Flaticon.ttf") format("truetype"), url("../fonts/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="flaticon2-"]:before, [class*=" flaticon2-"]:before, [class^="flaticon2-"]:after, [class*=" flaticon2-"]:after {
    font-family: Flaticon2, serif;
    font-size: 16px;
    font-style: normal;
}

.flaticon2-business:before {
    content: "\f100";
}

.flaticon2-user:before {
    content: "\f102";
}

.flaticon2-search:before {
    content: "\f101";
}
/* *********************** */

h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    letter-spacing: -0.02em;
    margin: 0 0 .75em 0;
}

/* Buttons =================== */
.btn-instagram {
    background-color: #1e78dd;
    color: #fff;
}

.btn-instagram:hover, .btn-instagram:active, .btn-instagram:focus {
    background-color: #3185e3;
    color: #fff;
}

.btn-instagram i {
    font-size: 12px;
    position: relative;
    top: -1px;
}

.btn-instagram.btn-sm {
    padding-left: 24px;
    padding-right: 24px;
}

.btn {
    border-radius: 2px;
    font-size: 13px;
    font-weight: 700;
    line-height: 1.5em;
    padding: 10px 15px;
}

.btn-lg {
    border-radius: 2px;
    font-size: 15px;
    line-height: 1.45em;
    padding: 14px 60px;
}

.btn-md {
    border-radius: 2px;
    font-size: 15px;
    line-height: 1.5em;
    padding: 10px 28px;
}

.btn-sm {
    border-radius: 2px;
    font-size: 11px;
    line-height: 1.5em;
    padding: 10px 38px;
}

.btn-xs {
    border-radius: 2px;
    font-size: 9px;
    line-height: 1.5em;
    padding: 5px 13px;
}

.btn:active, .btn.active {
    box-shadow: none;
}

.btn-icon-right i {
    margin-left: 10px;
    margin-right: 0;
}

.btn-gray, .btn-gray:focus {
    background: #008000;
    border: 1px solid #008000;
    color: #FFF
}

.btn-gray:hover {
    background: none;
    border-color: #008000;
    color: #008000;
}

.btn-darkgreen, .btn-darkgreen:focus {
    background: #008000;
    color:#fff;
}

.btn-darkgreen:hover {
    background: #00a000;
    color:#fff;
}

.group-button {
    line-height: 28px;
}
/* ======================== */

/* Select2 =================== */
.select2-container {
    width: 100% !important;
}

.select2-container--default .select2-selection--single {
    background: #F4F4F4 !important;
    border-color: #d1d0d0 !important;
    border-radius: 0 4px 4px 0 !important;
    height: 47px !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 44px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 10px !important;
}
/* ======================== */