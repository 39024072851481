.OldStandardTT{
    font-family: 'Old Standard TT', serif;
    font-weight: 400;
}

.thins {
    font-weight: 200;
}

.light {
    font-weight: 300;
}

.regurla {
    font-weight: 400;
}

.medium {
    font-weight: 600;
}

.bold {
    font-weight: 700;
}

.italic{
    font-style: italic;
}