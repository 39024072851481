#modal-client-register .modal-content {
    background: #F5F5F5;
    border-radius: 0;
}

#modal-client-register .modal-body {
    background: #f5f5f5;
    padding: 5px;
}

#modal-client-register .modal-header {
    background: #d8e9d7;
    margin-bottom: 20px;
    padding: 15px 25px;
}

#modal-client-register .modal-body .client-register-form {
    margin: 0 5%;
    max-width: 90%;
    padding: 10px 60px;
}