.quick-view-modal .modal-dialog {
    margin: 10% auto;
    width: 800px
}

.quick-view-modal .modal-content {
    border-radius: 0;
}

.quick-view-modal .modal-content button.close {
    margin-right: 10px;
    position: absolute;
    right: 0;
    top: 0;
    transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    -webkit-transition: all .5s;
    z-index: 100
}

.quick-view-modal .modal-content button.close:hover {
    opacity: 1
}

.quick-view-modal .modal-content .aa-product-view-slider .simpleLens-container {
    width: 100%
}

.quick-view-modal .modal-content .aa-product-view-slider .simpleLens-container .simpleLens-big-image-container {
    width: 100%
}

.quick-view-modal .modal-content .aa-product-view-slider .simpleLens-container .simpleLens-big-image-container .simpleLens-lens-image {
    width: 100%
}

.quick-view-modal .modal-content .aa-product-view-slider .simpleLens-thumbnails-container {
    margin-top: 10px;
    text-align: center
}

.quick-view-modal .modal-content .aa-product-view-content {
    padding-bottom: 10px;
    padding-right: 10px
}

.quick-view-modal .modal-content .aa-product-view-content h3 {
    font-size: 20px;
    margin-bottom: 5px;
    margin-top: 0;
    position: relative
}

.quick-view-modal .modal-content .aa-product-view-content h4 {
    letter-spacing: 1px
}

.quick-view-modal .modal-content .aa-product-view-content > p {
    font-size: 15px;
    letter-spacing: .5px;
    margin-bottom: 14px
}

.quick-view-modal .modal-content .aa-product-view-content .aa-price-block {
    margin-bottom: 5px;
    position: relative
}

.quick-view-modal .modal-content .aa-product-view-content .aa-price-block p {
    width: 100%;
}

.quick-view-modal .modal-content .aa-product-view-content .aa-price-block .old-price {
    color: #7a0600;
    font-size: 14px;
}

.quick-view-modal .modal-content .aa-product-view-content .aa-price-block small {
    font-size: 12px;
}

.quick-view-modal .modal-content .aa-product-view-content .aa-price-block .current-price {
    color: #1E2024;
    font-size: 18px;
}

.quick-view-modal .modal-content .aa-product-view-content .aa-price-block .aa-product-view-price {
    font-size: 24px;
    line-height: 22px;
}

.quick-view-modal .modal-content .aa-product-view-content .aa-price-block .aa-product-avilability {
    position: absolute;
    right: 0;
    top: 0
}

.quick-view-modal .modal-content .aa-product-view-content .aa-prod-view-size a {
    border: 1px solid #ddd;
    display: inline-block;
    font-size: 14px;
    letter-spacing: .5px;
    margin-bottom: 5px;
    margin-right: 8px;
    padding: 5px 10px;
    transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    -webkit-transition: all .5s
}

.quick-view-modal .modal-content .aa-product-view-content .aa-prod-quantity {
    margin-top: 10px
}

.quick-view-modal .modal-content .aa-product-view-content .aa-prod-quantity form {
    display: inline-block
}

.quick-view-modal .modal-content .aa-product-view-content .aa-prod-quantity form select {
    height: 25px;
    width: 40px
}

.quick-view-modal .modal-content .aa-product-view-content .aa-prod-quantity .aa-prod-category {
    display: inline-block;
    margin-left: 15px
}

.quick-view-modal .modal-content .aa-product-view-content .aa-prod-quantity .aa-prod-category a {
    font-size: 14px
}

.quick-view-modal .modal-content .aa-product-view-content .aa-prod-view-bottom {
    border-radius: 4px;
    border-top: 1px solid #ddd;
    margin-top: 10px;
    padding-top: 10px;
    display: inline-block;
    width: 100%;
}

.quick-view-modal .modal-content .aa-product-view-content .product-featured__price small {
    opacity: 0.5;
}

.quick-view-modal .modal-content .aa-product-view-content .product-featured__price p {
    margin: 0;
    font-weight: bold;
    font-size: 20px;
    color: #5fa80b;
}

.quick-view-modal .modal-content .aa-product-view-content .aa-prod-view-bottom a {
    border-radius: 4px;
}

@media (max-width: 991px) {
    .quick-view-modal .modal-dialog {
        margin: 10% auto;
        width: 90%
    }
}

@media (max-width: 360px) {
    .quick-view-modal .modal-content .aa-product-view-content .aa-prod-view-bottom {
        text-align: center
    }

    .quick-view-modal .modal-content .aa-product-view-content .aa-prod-view-bottom a:last-child {
        margin-left: 0;
        margin-top: 10px
    }

    .quick-view-modal .modal-content .aa-product-view-content {
        margin-top: 10px;
        text-align: center
    }

    .quick-view-modal .modal-content .aa-product-view-content .aa-price-block {
        text-align: left
    }

    .quick-view-modal .modal-content .aa-product-view-content h3 {
        border-bottom: 1px solid #ccc;
        padding-bottom: 5px
    }
}