#aa-product {
    color: rgba(0, 0, 0, 0.5);
    display: inline;
    float: none;
    font-size: 14px;
    line-height: initial;
    width: 100%;
}

#aa-product .aa-product-area {
    display: inline;
    float: left;
    width: 100%
}

#aa-product .aa-product-area .aa-product-inner {
    display: inline;
    float: left;
    width: 100%
}

#aa-product .aa-product-area .aa-product-inner .aa-product-catg {
    float: left
}

#aa-product .aa-product-area .aa-product-inner #men {
    padding-bottom: 35px;
    text-align: center
}

#aa-product .aa-product-area .aa-product-inner #electronics {
    padding-bottom: 35px;
    text-align: center
}

#aa-product .aa-product-area .aa-product-inner #women {
    padding-bottom: 35px;
    text-align: center
}

.aa-product-nav {
    display: inline-block;
    text-align: center;
    width: 100%
}

.aa-product-nav li {
    display: inline-block
}

.aa-product-nav li a {
    color: #333;
    display: inline-block;
    padding: 8px 15px
}

.aa-product-content {
    margin-left: -30px;
    margin-top: 20px
}

.aa-product-content li {
    background-color: #ccc;
    display: block;
    float: left;
    height: 250px;
    margin: 0 0 20px 30px;
    width: 22.5%
}

.aa-products-tab {
    border: 0;
    display: block;
    margin-bottom: 20px;
    text-align: center
}

.aa-products-tab li {
    border: 0;
    display: inline-block;
    float: none
}

.aa-products-tab li a {
    border: 0;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    font-size: 16px;
    margin: 0 6px;
    padding: 10px 12px;
    text-transform: uppercase;
    transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    -webkit-transition: all .5s
}

.aa-products-tab li a:hover, .aa-products-tab li a:focus {
    background-color: inherit;
    color: #333
}

.aa-products-tab li.active a {
    background-color: inherit;
    border: 0;
    font-size: 16px
}

.aa-products-tab li.active a:hover, .aa-products-tab li.active a:focus {
    background-color: inherit;
    border: 0
}

.aa-product-catg {
    margin-left: -54px;
    padding: 0;
}

.aa-product-catg li {
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    display: block;
    float: left;
    height: auto;
    margin: 0 0 0 55px;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-align: center;
    width: 20.5%
}

.aa-product-catg.home {
    margin-left: 0;
}

.aa-product-catg.home li {
    margin: 0 !important;
    width: calc(100% / 4) !important;
}

.aa-product-catg li figcaption {
    display: inline-block;
    max-width: 270px;
    min-height: 125px;
    text-align: left;
    vertical-align: top;
    width: 100%;
}

.aa-title-content {
    border-bottom: 1px solid;
    text-align: left;
}

.aa-product-catg li figure .aa-product-img img {
    transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    -webkit-transition: all .5s;
    width: 100%
}

.aa-product-catg li p {
    padding: 0
}

.aa-product-catg li:hover figure .aa-product-img img {
    transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    -webkit-transform: scale(1.05)
}

.aa-product-catg li:hover figure .aa-add-card-btn {
    transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -webkit-transform: scale(1)
}

.aa-product-catg.home li:hover .aa-product-hvr-content {
    transform: translateX(270%);
    -moz-transform: translateX(270%);
    -ms-transform: translateX(270%);
    -o-transform: translateX(270%);
    -webkit-transform: translateX(270%)
}

.aa-product-catg li figure {
    display: inline-block;
    font-size: 14px;
    height: auto;
    max-width: 270px;
    min-height: 230px;
    position: relative;
    width: 100%;
}

.aa-product-catg .list li figure {
    max-width: 100%;
}

.aa-product-catg li figure .aa-product-img {
    border-radius: 4px;
    display: inline-block;
    max-height: 225px;
    overflow: hidden;
    transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    -webkit-transition: all .5s
}

.aa-product-catg li figure .aa-add-card-btn {
    background: #000 none repeat scroll 0 0;
    bottom: 0;
    color: #fff;
    font-size: 14px;
    left: 0;
    padding: 12px 10px;
    position: absolute;
    right: 0;
    text-transform: uppercase;
    transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    -webkit-transform: scale(0);
    transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    -webkit-transition: all .5s;
    z-index: 2
}

.aa-product-catg li figure .aa-add-card-btn span {
    margin-right: 5px
}

.aa-product-catg li .aa-product-title {
    font-size: 16px;
    letter-spacing: -0.7px;
    line-height: 22px;
    margin-bottom: 0;
    min-height: 66px;
    overflow: hidden;
}

.aa-product-catg li .aa-product-title a {
    transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    -webkit-transition: all .5s
}

.aa-product-catg li .aa-product-sutia {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    height: 80px;
    line-height: 20px;
    overflow: hidden
}

.aa-product-catg li .aa-product-price {
    color: rgba(0, 0, 0, 0.5);
    font-size: 30px;
    letter-spacing: -0.5px;
    line-height: 25px;
}

.aa-product-catg li .aa-product-price del {
    margin-left: 5px
}

.meio .aa-product-price {
    margin-top: 5px;
}

.aa-product-catg li .aa-product-price .old-price, .meio .aa-product-price .old-price {
    color: #7a0600;
    font-size: 14px;
}

.aa-product-catg li .aa-product-price .current-price, .meio .aa-product-price .current-price {
    color: #1E2024;
    font-size: 16px;
}

.aa-product-catg li .aa-product-price del, .meio .aa-product-price del {
    margin-left: 5px
}

.aa-product-catg li .aa-product-hvr-content {
    position: relative;
    right: 0;
    text-align: center;
    top: -68%;
    transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    width: 45px;
    z-index: 2;
}

.aa-product-catg li .aa-product-hvr-content a {
    color: #fff;
    display: inline-block;
    font-size: 18px;
    margin: 0 5px;
    padding: 5px 10px;
}

.aa-product-catg li:hover .aa-product-hvr-content {
    right: calc((-55%) - 12px);
}

.aa-product-catg li .aa-badge {
    border-radius: 0 0 4px 0;
    color: #fff;
    font-size: 15px;
    left: 0;
    padding: 5px 10px;
    position: absolute;
    top: 0;
    z-index: 2
}

.aa-product-catg li .aa-sale {
    background-color: #008000
}

.aa-product-catg li .aa-sold-out {
    background-color: #f00
}

.aa-product-catg li .aa-hot {
    background-color: #f60
}

.aa-add-to-cart-btn {
    background: #008000;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    letter-spacing: 0.5px;
    margin-top: 5px;
    padding: 10px 15px;
    text-transform: uppercase;
    transition: all .5s ease 0s
}

.aa-add-to-cart-btn span {
    margin-right: 5px
}

.aa-add-to-cart-btn:hover, .aa-add-to-cart-btn:focus {
    background: transparent;
    border-color: #008000;
    color: #008000;
}

.aa-filter-btn {
    color: #fff;
    font-size: 15px;
    height: 30px;
    width: 80px
}

.aa-cart-view-btn {
    color: #fff;
    float: right;
    font-size: 16px;
    letter-spacing: 2px;
    padding: 15px 30px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    -webkit-transition: all .5s
}

.aa-product-catg li figure .shadow {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    height: calc(100% - 4px);
    opacity: 0;
    position: absolute;
    right: 0;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    width: 100%;
    z-index: 1;
}

.aa-product-catg li:hover .shadow {
    opacity: 1;
}

.aa-prod-options .select2-container--default {
    text-transform: uppercase !important;
}

#aa-product-category {
    display: inline-block;
    width: 100%;
}

#aa-product-category .aa-product-catg-content {
    float: left;
    padding: 0;
    width: 100%
}

#aa-product-category .aa-product-catg-content .aa-product-catg-head {
    background-color: transparent;
    border: none;
    display: inline;
    float: left;
    left: 0;
    padding: 10px 15px;
    position: absolute;
    top: 10px;
    width: 100%
}

#aa-product-category .aa-product-catg-content .aa-product-catg-head .aa-product-catg-head-left {
    float: left
}

#aa-product-category .aa-product-catg-content .aa-product-catg-head .aa-product-catg-head-left label {
    margin-bottom: 0;
    margin-right: 10px
}

#aa-product-category .aa-product-catg-content .aa-product-catg-head .aa-product-catg-head-left .aa-sort-form {
    float: left
}

#aa-product-category .aa-product-catg-content .aa-product-catg-head .aa-product-catg-head-left .aa-sort-form select {
    border: 1px solid #ccc;
    text-align: center;
    width: 150px
}

#aa-product-category .aa-product-catg-content .aa-product-catg-head .aa-product-catg-head-left .aa-sort-form select option {
    padding: 4px
}

#aa-product-category .aa-product-catg-content .aa-product-catg-head .aa-product-catg-head-left .aa-show-form {
    float: left;
    margin-left: 25px
}

#aa-product-category .aa-product-catg-content .aa-product-catg-head .aa-product-catg-head-left .aa-show-form select {
    border: 1px solid #ccc;
    text-align: center;
    width: 80px
}

#aa-product-category .aa-product-catg-content .aa-product-catg-head .aa-product-catg-head-left .aa-show-form select option {
    padding: 4px
}

#aa-product-category .aa-product-catg-content .aa-product-catg-head .aa-product-catg-head-right {
    float: right
}

#aa-product-category .aa-product-catg-content .aa-product-catg-head .aa-product-catg-head-right a {
    color: #fff;
    display: inline-block;
    font-size: 20px;
    margin-left: 5px;
    margin-top: 3px;
    padding: 0;
    transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    -webkit-transition: all .5s
}

#aa-product-category .aa-product-catg-content .aa-product-catg-head .aa-product-catg-head-right a .fa {
    color: #fff;
}

#aa-product-category .aa-product-catg-content .aa-product-catg-body {
    display: inline;
    float: left;
    margin-left: -12px;
    width: calc(100% + 24px);
}

#aa-product-category .aa-product-catg-content .aa-product-catg-body .aa-product-catg {
    display: inline;
}

#aa-product-category .aa-product-catg-content .aa-product-catg-body .aa-product-catg li {
    border: none;
    border-radius: 4px;
    margin: 0 12px 0;
    width: 29.916%
}

#aa-product-category .aa-product-catg-content .aa-product-catg-body .aa-product-catg li .aa-product-descrip {
    display: none
}

#aa-product-category .aa-product-catg-content .aa-product-catg-body .list li {
    border-bottom: solid 1px #ffffff;
    height: 345px;
    padding-bottom: 15px;
    width: 100%
}

#aa-product-category .aa-product-catg-content .aa-product-catg-body .list li .btn {
    bottom: 15px;
    position: absolute;
    right: 15px
}

#aa-product-category .aa-product-catg-content .aa-product-catg-body .list li figure {
    max-width: 100% !important
}

#aa-product-category .aa-product-catg-content .aa-product-catg-body .list li figure .aa-product-img {
    float: left;
    margin-right: 40px;
    width: 35%
}

#aa-product-category .aa-product-catg-content .aa-product-catg-body .list li figure .aa-add-card-btn {
    bottom: 35%;
    font-size: 14px;
    left: 40%;
    transform: scale(1);
    width: 240px
}

#aa-product-category .aa-product-catg-content .aa-product-catg-body .list li figure figcaption {
    text-align: left
}

#aa-product-category .aa-product-catg-content .aa-product-catg-body .list li figure .aa-product-descrip {
    display: block
}

#aa-product-category .aa-product-catg-content .aa-product-catg-body .list li .aa-product-hvr-content {
    width: 35%
}

/********************************************************************************/
/********************************************************************************/
/********************************************************************************/
/********************************************************************************/
/********************************************************************************/
/********************************************************************************/
.product-page {
    color: #000;
    display: inline;
    float: none;
    font-size: 14px;
    width: 100%;
}

.product-page p img {
    height: auto !important;
    max-width: 100%;
}

.product-page .panel {
    border-radius: 6px;
    display: inline-block;
    padding: 5px;
    width: auto;
}

.product-page .a2a_kit {
    bottom: initial;
    margin: 0 auto 10px;
    max-width: 252px;
    right: initial;
}

.product-page .col-cback-btn {
    bottom: 0;
    position: absolute;
    right: 0;
    width: calc(100% - 220px);
}

.product-page .col-cback-btn .cback-info {
    padding-left: 35px;
    position: relative;
}

.product-page .col-cback-btn .cback-info i {
    color: #01AC66;
    font-size: 24px;
    left: 10px;
    position: absolute;
    top: 10px;
}

.product-page .product-price {
    line-height: 20px;
    padding: 0 15px 15px;
}

.product-page .product-price small {
    opacity: 0.5;
}

.product-page .product-price p {
    color: #5fa80b;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
}

.product-page .nav-tabs {
    border: none;
    display: inline-block;
    margin-top: 25px;
    text-align: left;
    width: 100%;
}

.product-page .nav-tabs li {
    background: transparent !important;
    border: transparent;
    display: inline-block;
    float: none;
    font-size: 18px;
}

.product-page .nav-tabs li a, .product-page .nav-tabs li a:focus {
    background: transparent !important;
    border: none transparent !important;
    border-bottom: solid 2px transparent !important;
    color: #9a9a9a;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 8px;
    margin-right: 25px;
}

.product-page .nav-tabs li.active a {
    border-bottom: solid 3px #3faf6c !important;
    color: #042F3F;
}

.product-page .well {
    background: transparent;
    border: none;
    box-shadow: none;
}

/* Related Products =================== */
.product-page .product-related-item {
    display: inline;
    float: left;
    width: 100%
}

.product-page .product-related-item > h3 {
    border-bottom: 1px solid #ccc;
    font-size: 20px;
    margin-bottom: 30px;
    padding-bottom: 20px;
    text-align: center
}

.product-page .product-related-item .slick-prev, .product-page .product-related-item .slick-next {
    background: #073D49;
    height: 40px;
    top: -58px;
    width: 35px;
}

.product-page .product-related-item .slick-prev:before, .product-page .product-related-item .slick-next:before {
    font-family: FontAwesome, serif;
}

.product-page .product-related-item .slick-prev:before {
    content: "\f053";
}

.product-page .product-related-item .slick-prev {
    border-radius: 10px 0 0 10px;
    left: auto !important;
    right: 36px;
}

.product-page .product-related-item .slick-next:before {
    content: "\f054";
}

.product-page .product-related-item .slick-next {
    border-radius: 0 10px 10px 0;
    right: 0;
}

.product-page .product-related-item .slick-dots {
    display: none !important
}

/* ======================== */
.cart-row div {
    margin: 0;
    text-align: center;
}

.h3-price {
    color: #011820;
    margin: 0;
}

.custom-grid-products .aa-sidebar-widget ul {
    padding: 10px 0 0 10px;
}

.custom-grid-products .aa-product-img img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.custom-grid-products .aa-product-title {
    font-size: 15px;
    padding: 0 5px;
}

.custom-grid-products figure .shadow {
    background: transparent;
    height: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    width: 100%;
    z-index: 1;
}

.custom-grid-products .aa-product-catg li:hover .shadow {
    opacity: 1;
}

.custom-grid-products .aa-product-catg.list .shadow {
    display: none;
}

.custom-grid-products .aa-product-catg-head {
    border-radius: 4px;
    margin-bottom: 20px;
}

#aa-product .aa-product-catg {
    min-width: 100%;
}

.aa-product-catg.list li, .aa-product-catg.list figure {
    height: auto !important;
}

.aa-product-catg.list figure {
    min-height: 200px;
}

#aa-product-category .aa-product-catg-content .aa-product-catg-body .list li {
    max-width: 850px;
}

#Carr_ADD_Item {
    z-index: 10000;
}

/*
 ##############################
 #### Gallery in Quick-view ###
 ##############################
 */
.gallery-product figure {
    overflow: hidden;
    position: relative;
}

.gallery-product figure .shadow {
    background: #0009;
    height: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    width: 100%;
    z-index: 1;
}

.gallery-product figure:hover .shadow {
    opacity: 1;
}

.gallery-product figure i {
    color: #fff;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    right: 200%;
    text-align: center;
    top: calc(50% - 15px);
    transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    width: 45px;
    z-index: 2;
}

.gallery-product figure:hover i {
    right: calc(50% - 66px);
}

.gallery-product .photoswipe {
    height: 55px;
    overflow: hidden;
    text-align: center
}

.gallery-product .photoswipe figure {
    border: 1px solid #dedede;
    border-radius: 4px;
    display: inline-block;
    height: 49px;
    margin: 5px 0 0;
    width: 48px;
}

.gallery-product .photoswipe figure:hover {
    background: #000;
}

.gallery-product .photoswipe figure:hover a {
    opacity: 0.6;
}

.gallery-product figure img, .gallery-product .photoswipe figure img {
    height: 100%;
    transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    width: 100%;
    object-fit: cover;
}

.gallery-product figure:hover img, .gallery-product .photoswipe figure:hover img {
    transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    -webkit-transform: scale(1.5);
}

.gallery-product .figure {
    position: relative
}

.gallery-product .btn-video {
    border-radius: 4px;
    bottom: 65px;
    color: #fff;
    padding: 2px 12px;
    position: absolute;
    right: 10px;
    z-index: 2
}

#prodModal_video {
    background: rgba(0, 0, 0, 0.7)
}

#prodModal_video .modal-dialog {
    max-width: 1000px;
    width: 100%
}

#prodModal_video .modal-dialog .modal-content {
    background: #000;
    border-radius: 15px;
}

#prodModal_video .modal-dialog .modal-content .modal-body {
    border-radius: 15px;
    overflow: hidden;
    padding: 0
}

@media (max-width: 1200px) {
    .product-page .col-cback-btn {
        width: 250px;
    }
}

@media (max-width: 991px) {
    .aa-product-catg {
        margin-left: -20px
    }

    .aa-product-catg li {
        margin: 0 0 20px 20px;
        width: 30.5%
    }

    #aa-product-category .aa-product-catg-content .aa-product-catg-body .aa-product-catg li {
        width: 44%
    }

    #aa-product-category .aa-product-catg-content .aa-product-catg-body .list li {
        width: 100%
    }

    #aa-product-category .aa-product-catg-content .aa-product-catg-body .list li figure .aa-product-descrip {
        padding-right: 20px
    }

    #aa-product-category .aa-product-catg-content .aa-product-catg-body .list li figure .aa-product-img {
        margin-right: 20px;
        width: 42%
    }

    #aa-product-category .aa-product-catg-content .aa-product-catg-body .list li figure .aa-product-img img {
        max-width: 100%
    }

    #aa-product-category .aa-product-catg-content .aa-product-catg-body .list li figure .aa-product-descrip {
        font-size: 15px;
        padding-right: 25px
    }

    #aa-product-category .aa-product-catg-content .aa-product-catg-body .list li figure .aa-add-card-btn {
        bottom: 20%;
        left: 46%
    }

    #aa-product-category .aa-product-catg-content .aa-product-catg-body .list li .aa-product-hvr-content {
        width: 42%
    }

    #aa-product-details {
        margin: 0 auto;
        width: 100%
    }

    .aa-product-view-slider .simpleLens-big-image-container {
        width: 100%
    }

    .aa-product-view-slider .simpleLens-big-image-container .simpleLens-lens-image {
        width: 100%
    }

    .cart-view-table .table-responsive .aa-cart-view-bottom .aa-cart-view-btn {
        float: none;
        margin-top: 20px
    }
}

@media (max-width: 820px) {
    .aa-product-catg.home li {
        width: calc(100% / 2 - 5px) !important;
    }
}

@media (max-width: 767px) {
    .product-page .col-cback-btn {
        position: relative;
        width: 100%;
    }

    #aa-product .aa-product-area {
        padding: 0 15px
    }

    #aa-popular-category .aa-popular-category-area {
        padding: 0 15px
    }

    #aa-product-category .aa-product-catg-content .aa-product-catg-body .aa-product-catg li {
        width: 45%
    }

    #aa-product-category .aa-product-catg-content .aa-product-catg-body .list li {
        width: 100%
    }

    #aa-product-details .aa-product-view-content {
        padding: 0 30px;
        text-align: center
    }

    #cart-view .cart-view-area .cart-view-table .table tbody tr .aa-cart-view-bottom .aa-cart-coupon {
        float: none
    }

    .cart-view-table .table-responsive .aa-cart-view-bottom .aa-cart-view-btn {
        float: none;
        margin-top: 20px
    }

    #cart-view .cart-view-area .cart-view-table .aa-cart-view-btn {
        font-size: 15px;
        padding: 12px
    }

    #cart-view .cart-view-area .cart-view-table .table tbody tr .aa-cart-view-bottom {
        padding: 15px
    }

    #cart-view .cart-view-area .cart-view-table .table tbody tr .aa-cart-view-bottom .aa-cart-coupon .aa-coupon-code {
        font-size: 15px;
        margin-right: 15px;
        min-width: 100px;
        padding: 12px;
        width: 150px
    }
}

@media (max-width: 640px) {
    .aa-product-catg {
        margin-left: -20px
    }

    .aa-product-catg li {
        margin: 0 0 20px 20px;
        width: 46%
    }

    #aa-product-category .aa-product-catg-content .aa-product-catg-body .aa-product-catg {
        margin-left: 0
    }

    #aa-product-category .aa-product-catg-content .aa-product-catg-body .aa-product-catg li {
        margin-left: 0;
        width: 100%
    }

    #aa-product-category .aa-product-catg-content .aa-product-catg-body .list li {
        width: 100%
    }

    #cart-view .cart-view-area .cart-view-table .table tbody tr td img {
        height: 40px;
        width: 40px
    }

    #cart-view .cart-view-area .cart-view-table .table thead tr th {
        font-size: 16px;
        padding: 10px 0
    }

    #cart-view .cart-view-area .cart-view-table .table tbody tr td {
        font-size: 14px
    }

    #cart-view .cart-view-area .cart-view-table .table tbody tr .aa-cart-view-bottom .aa-cart-coupon .aa-coupon-code {
        font-size: 12px;
        margin-right: 10px;
        min-width: 100px;
        padding: 8px;
        width: 100px
    }

    #cart-view .cart-view-area .cart-view-table .aa-cart-view-btn {
        font-size: 12px;
        padding: 10px
    }

    #cart-view .cart-view-area .cart-view-table .cart-view-total h4 {
        font-size: 20px
    }
}

@media (min-width: 550px) and (max-width: 640px) {
    .aa-product-catg li:hover .aa-product-hvr-content {
        right: calc((-30%) - 12px);
        top: -55%;
    }
}

@media (max-width: 480px) {
    .product-page .product-related-item .slick-prev, .product-page .product-related-item .slick-next {
        top: 120px;
    }

    .product-page .product-related-item .slick-prev {
        left: 10px;
        right: auto;
        z-index: 3;
    }

    .aa-product-catg {
        margin-left: 0
    }

    .aa-product-catg li {
        margin-left: 0;
        width: 100%
    }

    #aa-product .aa-product-area {
        margin-top: 20px
    }

    .aa-products-tab li a {
        margin: 0 4px;
        padding: 6px
    }

    #aa-product-category .aa-product-catg-content .aa-product-catg-head .aa-product-catg-head-left .aa-sort-form select {
        padding: 0 5px 0 0;
        width: auto
    }

    #aa-product-category .aa-product-catg-content .aa-product-catg-head .aa-product-catg-head-left .aa-show-form select {
        padding: 0 5px 0 0;
        width: auto
    }

    #aa-product-category .aa-product-catg-content .aa-product-catg-body .list li {
        height: 500px;
        max-width: 100%;
        width: 100%
    }

    #aa-product-category .aa-product-catg-content .aa-product-catg-body .list li figure .aa-product-img {
        margin-bottom: 20px;
        margin-right: 0;
        width: 100%
    }

    #aa-product-category .aa-product-catg-content .aa-product-catg-body .list li figure .aa-add-card-btn {
        bottom: 0;
        left: 0;
        width: 100%
    }

    #aa-product-category .aa-product-catg-content .aa-product-catg-body .list li .aa-product-hvr-content {
        top: 40%;
        width: 100%
    }
}

@media (max-width: 360px) {
    .aa-add-to-cart-btn {
        min-width: 150px
    }

    #aa-product-details .aa-product-view-content {
        padding: 0 10px
    }

    .product-page .product-related-item h3 {
        text-align: left
    }

    #cart-view .cart-view-area .cart-view-table {
        padding: 0 0 20px
    }

    #cart-view .cart-view-area .cart-view-table .table tbody tr td img {
        height: 15px;
        width: 15px
    }

    #cart-view .cart-view-area .cart-view-table .table thead tr th {
        font-size: 10px;
        padding: 7px 0
    }

    #cart-view .cart-view-area .cart-view-table .table tbody tr td {
        font-size: 14px
    }

    #cart-view .cart-view-area .cart-view-table .table tbody tr .aa-cart-view-bottom .aa-cart-coupon .aa-coupon-code {
        font-size: 12px;
        margin-right: 10px;
        min-width: 100px;
        padding: 8px;
        width: 100px
    }

    #cart-view .cart-view-area .cart-view-table .aa-cart-view-btn {
        font-size: 12px;
        padding: 10px
    }

    #cart-view .cart-view-area .cart-view-table .cart-view-total h4 {
        font-size: 20px
    }

    #cart-view .cart-view-area .cart-view-table .cart-view-total {
        width: auto
    }

    .aa-wishlist-table .aa-add-to-cart-btn {
        font-size: 7px;
        min-width: initial;
        padding: 8px 0
    }

    #cart-view .cart-view-area .aa-wishlist-table .table thead tr th {
        font-size: 14px;
        padding: 10px 0
    }
}

@media (max-width: 320px) {
    #aa-product-category .aa-product-catg-content .aa-product-catg-head .aa-product-catg-head-left .aa-show-form {
        margin-left: 5px
    }

    #aa-product-category .aa-product-catg-content .aa-product-catg-head .aa-product-catg-head-left label {
        margin-right: 0
    }

    #cart-view .cart-view-area .cart-view-table .table tbody tr td {
        font-size: 10px
    }

    #cart-view .cart-view-area .cart-view-table .aa-cart-view-btn {
        font-size: 10px;
        letter-spacing: .5px
    }

    #cart-view .cart-view-area .cart-view-table .table tbody tr .aa-cart-view-bottom .aa-cart-coupon .aa-coupon-code {
        font-size: 10px;
        min-width: initial;
        padding: 6px;
        width: 90px
    }
}
