/* Buttons
======================= */
.btn-square {
    border-radius: 0;
}

.btn-rounded {
    border-radius: 4px;
}

.btn-ball {
    border-radius: 50%;
}

.btn-circled {
    border-radius: 25px;
}

.btn-block {
    overflow: hidden;
}

/* Size */
.bt-lg {
    padding: 18px 30px;
    max-width: 100%;
}

.bt-md {
    padding: 18px 30px;
    max-width: 100%;
}

.bt-sm {
    border-radius: 2px;
    font-size: 11px;
    line-height: 1.5em;
    padding: 10px 38px;
}

.bt-xs {
    border-radius: 2px;
    font-size: 10px;
    line-height: 1.5em;
    padding: 5px 13px;
}
/********************/

/* Background */
.bt-success {
    background: #3faf6c;
}

.bt-success-inverse {
    background: #00BE90;
}

.bt-white-outline {
    background: none;
    border: 2px solid #fff;
}

.bt-gray-outline {
    background: none;
    border: 2px solid #e4e7ed;
}

.bt-red {
    background: #d55034;
}

.bt-blue{
    background: #0358a8;
}

/* Background:hover */
.bt-success:hover, .bt-success-hover:hover {
    background: none;
    box-shadow: 0 0 0 2px #0F7C55 inset;
}

.bt-success-inverse:hover, .bt-success-inverse-hover:hover {
    background: none;
    box-shadow: 0 0 0 2px #00BE90 inset;
}

.bt-orange-hover:hover {
    border: 2px solid #FFC265;
}

.btn-gradient{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff8e64+22,ffe641+81 */
    background: rgb(255,142,100); /* Old browsers */
    background: -moz-linear-gradient(-45deg, rgba(255,142,100,1) 22%, rgba(255,230,65,1) 81%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(255,142,100,1) 22%,rgba(255,230,65,1) 81%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(255,142,100,1) 22%,rgba(255,230,65,1) 81%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff8e64', endColorstr='#ffe641',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    border: none;
    border-radius: 8px;
}
/* *********************** */