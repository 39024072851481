/* Font Size
======================= */
.f10 {
    font-size: 10px;
}

.f12 {
    font-size: 12px;
}

.f14 {
    font-size: 14px;
}

.f15 {
    font-size: 15px;
}

.f16 {
    font-size: 16px;
}

.f18 {
    font-size: 18px;
}

.f20 {
    font-size: 20px;
}

.f22 {
    font-size: 22px;
}

.f24 {
    font-size: 24px;
}

.f28 {
    font-size: 28px;
}

.f30 {
    font-size: 30px;
}

.f32 {
    font-size: 32px;
}
.f40{
    font-size: 40px;
}

.f48 {
    font-size: 48px;
}

.f80 {
    font-size: 80px;
}
/* *********************** */