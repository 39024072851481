.column {
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
}

.size-1of4 {
    width: 25%;
}

.size-1of3 {
    width: 33.333%;
}

.size-1of2 {
    width: 50%;
}

#salvattore[data-columns]::before {
    content: '1 .column';
}


@media screen and (min-width: 991px) {
    #salvattore.salvattore-lg-4[data-columns]::before{
        content: '4 .column.size-1of4';
    }
    #salvattore.salvattore-lg-3[data-columns]::before{
        content: '3 .column.size-1of3';
    }
    #salvattore.salvattore-lg-2[data-columns]::before{
        content: '2 .column.size-1of2';
    }
    #salvattore.salvattore-lg-1[data-columns]::before{
        content: '1 .column';
    }
}

@media screen and (max-width: 991px) {
    #salvattore.salvattore-md-4[data-columns]::before{
        content: '4 .column.size-1of4';
    }
    #salvattore.salvattore-md-3[data-columns]::before{
        content: '3 .column.size-1of3';
    }
    #salvattore.salvattore-md-2[data-columns]::before{
        content: '2 .column.size-1of2';
    }
    #salvattore.salvattore-md-1[data-columns]::before{
        content: '1 .column';
    }
}

@media screen and (max-width: 767px) {
    #salvattore.salvattore-sm-4[data-columns]::before{
        content: '4 .column.size-1of4';
    }
    #salvattore.salvattore-sm-3[data-columns]::before{
        content: '3 .column.size-1of3';
    }
    #salvattore.salvattore-sm-2[data-columns]::before{
        content: '2 .column.size-1of2';
    }
    #salvattore.salvattore-sm-1[data-columns]::before{
        content: '1 .column';
    }
}

@media screen and (max-width: 500px) {
    #salvattore.salvattore-xs-4[data-columns]::before{
        content: '4 .column.size-1of4';
    }
    #salvattore.salvattore-xs-3[data-columns]::before{
        content: '3 .column.size-1of3';
    }
    #salvattore.salvattore-xs-2[data-columns]::before{
        content: '2 .column.size-1of2';
    }
    #salvattore.salvattore-xs-1[data-columns]::before{
        content: '1 .column';
    }
}