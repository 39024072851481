body {
    background: #EAEAEA;
    color: #384340;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    overflow-x: hidden;
    padding-top: 126px;
}

body.body-home {
    padding-top: 0;
}

/* Reset
======================= */
a, a:focus, a:hover, button, button:focus, button:hover {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    outline: none;
    text-decoration: none;
    color: inherit;
}

textarea {
    resize: none;
}

.icheckbox_line-blue, .iradio_line-blue {
    background: #94c7e6 !important;
}

.icheckbox_line-blue.checked, .iradio_line-blue.checked {
    background: #2489c5 !important;
}
/* *********************** */

/* Backgrounds
======================= */
.bg-green-light {
    background-color: #79CE3D;
}

.bg-green-medium {
    background-color: #01AC66;
}

.bg-green-dark {
    background-color: #0F7C55;
}

.bg-green-inverse {
    background-color: #00BE90;
}

.bg-black {
    background-color: #1c1c1c !important;
}
/* *********************** */

/* Links
======================= */
/* Color hover */
a.hover-green-dark:hover {
    color: #0F7C55;
}

a.hover-green-light:hover {
    color: #79CE3D;
}

a.hover-black:hover {
    color: #1c1c2b;
}

a.hover-white:hover {
    color: #fff;
}

a.hover-orange:hover {
    color: #FFC265;
}

a.bg-hover-black:hover {
    background: #1c1c2b;
}
/* *********************** */

/* Fonts
======================= */
/* Weight */
.light {
    font-weight: 400;
}

.medium {
    font-weight: 600;
}

.bold {
    font-weight: 800;
}

.fw300 {
    font-weight: 300 !important;
}

.fw400 {
    font-weight: 400 !important;
}

.fw700 {
    font-weight: 700 !important;
}

/* Color */
.white, a.white, a.white:focus {
    color: #fff;
}

.green-light, a.green-light, a.green-light:focus {
    color: #79CE3D;
}

.green-medium, a.green-medium, a.green-medium:focus {
    color: #01AC66;
}

.green-dark, a.green-dark, a.green-dark:focus {
    color: #0F7C55;
}

.green, a.green, a.green:focus {
    color: #167A62;
}

.black, a.black, a.black:focus {
    color: #1c1c2b;
}

.orange-light, a.orange-light, a.orange-light:focus {
    color: #FFC265;
}

.orange, a.orange, a.orange:focus {
    color: #ff9d3c;
}

.gray, a.gray, a.gray:focus {
    color: #A3A3A3;
}

/* Size */
.f10 {
    font-size: 10px;
}

.f12 {
    font-size: 12px;
}

.f14 {
    font-size: 14px;
}

.f16 {
    font-size: 16px;
}

.f18 {
    font-size: 18px;
}

.f20 {
    font-size: 20px;
}

.f22 {
    font-size: 22px;
}

.f24 {
    font-size: 24px;
}

.f28 {
    font-size: 28px;
}

.f32 {
    font-size: 32px;
}

.f48 {
    font-size: 48px;
}
/* *********************** */

/* Buttons
======================= */
.btn-block {
    overflow: hidden;
}

/* Size */
.bt-lg {
    padding: 18px 30px;
    max-width: 100%;
    width: 245px;
}

.bt-md {
    padding: 18px 30px;
    max-width: 100%;
    width: 245px;
}

.bt-sm {
    border-radius: 2px;
    font-size: 11px;
    line-height: 1.5em;
    padding: 10px 38px;
}

.bt-xs {
    border-radius: 2px;
    font-size: 10px;
    line-height: 1.5em;
    padding: 5px 13px;
}

/* Background */
.bt-success {
   background: #0F7C55;
}

.bt-success-inverse {
    background: #00BE90;
}

.bt-white-outline {
    background: none;
    border: 2px solid #fff;
}

.bt-gray-outline {
    background: none;
    border: 2px solid #e4e7ed;
}

/* Background:hover */
.bt-success:hover, .bt-success-hover:hover {
    background: none;
    box-shadow: 0 0 0 2px #0F7C55 inset;
}

.bt-success-inverse:hover, .bt-success-inverse-hover:hover {
    background: none;
    box-shadow: 0 0 0 2px #00BE90 inset;
}

.bt-orange-hover:hover {
    border: 2px solid #FFC265;
}
/* *********************** */

/* Displays
======================= */
.dtable-full {
    display: table;
    width: 100%;
}

.inline {
    display: inline;
}

.inline-block {
    display: inline-block;
}

.inline-block-full {
    display: inline-block;
    width: 100%;
}

.block {
    display: block;
}
/* *********************** */

/* Margin
======================= */
.margin-t0 {
    margin-top: 0;
}

.margin-t5 {
    margin-top: 5px;
}

.margin-t10 {
    margin-top: 10px;
}

.margin-r10 {
    margin-right: 10px;
}

.margin-b0 {
    margin-bottom: 0;
}

.margin-b5 {
    margin-bottom: 5px;
}

.margin-b10 {
    margin-bottom: 10px;
}

.margin-l10 {
    margin-left: 10px;
}

.margin-t20 {
    margin-top: 20px;
}

.margin-r20 {
    margin-right: 20px;
}

.margin-b20 {
    margin-bottom: 20px;
}

.margin-l20 {
    margin-left: 20px;
}
/* *********************** */


/* Sections Padding
======================= */
.section-mini {
    min-height: 300px;
    padding: 20px 0;
}

.section-small {
    padding: 40px 0;
}

.section-medium {
    padding: 60px 0;
}

.section-large {
    padding: 80px 0;
}
/* *********************** */

/* Jquery Lazy Config
/* *********************** */
.lazyload {
    background: url('../../images/preloader.gif') no-repeat 50% 50%;
    background-size: 50px 50px;
    width: 100%;
}
/* *********************** */

/* Photoswipe Config
/* *********************** */
.pswp__caption__center {
    text-align: center;
}
/* *********************** */

/* Inputs
======================= */
.form-control, .form-control:focus {
    border-radius: 2px;
    border:1px solid #dfdfdf;
    box-shadow: none;
    width: 100%;
}

input.form-control, input.form-control:focus {
    height: 48px;
}

.form-control:focus {
    border-color: orange;
}

.has-feedback label ~ .form-control-feedback {
    top: 37px;
}

.input-group {
    width: 100%;
}

.input-group-addon {
    background: #f7f7f7;
    width: 15%;
    max-width: 100px;
    min-width: 70px;
    font-size: 20px;
}
/* *********************** */

/* Select2
======================= */
.select2-container {
    width: 100% !important;
}

.select2-container--default .select2-selection--single {
    background: none;
    border-color: #dfdfdf !important;
    border-radius: 0 !important;
    height: 48px !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 44px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 10px !important;
}
/* ======================== */

@media (max-width: 1200px) {
    body {
        padding-top: 123px;
    }
}

@media (max-width: 991px) {
    body {
        padding-top: 107px;
    }
}

@media (max-width: 767px) {
    body {
        padding-top: 123px;
    }
}

@media (max-width: 500px) {
    .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11 {
        width: 100%;
    }
}