/*https://codepen.io/whqet/pen/EJgwb*/
#checkout-progress .progress-mini {
    margin-bottom: 20px;
    padding: 20px 15px;
}

#checkout-progress {
    margin-top: 15px;
    height: 60px;
}

#checkout-progress .progress {
    border-radius: 10px;
    overflow: inherit;
    position: relative;
}

#checkout-progress .progress .progress-bar {
    border-radius: 10px;
}

#checkout-progress .progress ul {
    margin: 0;
    position: absolute;
    width: 100%;
}

#checkout-progress .progress li {
    padding: 0;
    position: relative;
    text-align: center;
    width: calc((100% - 13px) / 4);
}

#checkout-progress .progress li span {
    color: #ccc;
    display: inline-block;
    font-size: 16px;
    margin-top: 35px;
}

#checkout-progress .progress li .fa {
    background: #ddd;
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: inset 2px 2px 2px 0 #0003;
    color: #fff;
    font-size: 18px;
    left: calc(50% - 22px);
    padding: 12px 0 11px;
    position: absolute;
    text-align: center;
    text-shadow: 1px 1px #0003;
    top: -13px;
    width: 45px;
}

#checkout-progress .progress li.visited span {
    color: #57AED1;
}

#checkout-progress .progress li.visited .fa {
    background: #57AED1;
}

#checkout-progress .progress li.active span {
    color: #8BC53F;
}

#checkout-progress .progress li.active .fa {
    background: #8BC53F;
}

#checkout-progress .progress .progress-bar.step-one {
    width: 10%;
}

#checkout-progress .progress .progress-bar.step-two {
    width: 40%;
}

#checkout-progress .progress .progress-bar.step-three {
    width: 60%;
}

#checkout-progress .progress .progress-bar.step-four {
    width: 100%;
}

@media (max-width: 600px) {
    #checkout-progress .progress li span {
        font-size: 13px;
    }
}

@media (max-width: 500px) {
    .progress-mini {
        margin-left: 0;
    }
}

@media (max-width: 400px) {
    #checkout-progress .progress {
        margin-top: 20px;
    }

    #checkout-progress .progress li:nth-child(2n+0) span {
        position: relative;
        top: -70px;
    }
}