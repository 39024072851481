section.gallery {
    margin-top: 10px;
    border-top: 1px solid #e4e7ed;
    padding-top: 10px;
}

section.gallery figure {
    background-color: #171d21;
    border-radius: 4px;
    display: block;
    float: left;
    height: 170px;
    margin: 1px;
    overflow: hidden;
    position: relative;
    width: calc((100% - 6px) / 3);
}

section.gallery figure a {
    display: inline-block;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 1;
}

section.gallery figure img {
    height: 100% !important;
    min-width: 100%;
    opacity: 1;
    transition: opacity 0.3s ease;
    -webkit-transition: opacity 0.3s ease;
    width: auto;
    object-fit: cover;
}

section.gallery figure .gallery_item-desc {
    display: block;
    left: 0;
    opacity: 0;
    padding: 0 10px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: scale(0.95) translateY(-50%);
    -webkit-transform: scale(0.95) translateY(-50%);
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    visibility: hidden;
}

section.gallery figure .gallery_item-desc h4 {
    color: #fff;
    font-size: 14px;
    line-height: 1.1em;
    margin-bottom: .35em;
    text-transform: uppercase;
    word-break: break-all;
}

section.gallery figure .gallery_item-desc time {
    color: #9a9da2;
    display: block;
    font-size: 10px;
    font-family: 'montserratextrabold', serif;
    letter-spacing: -0.02em;
    line-height: 1.2em;
    margin-bottom: .9em;
    text-transform: uppercase;
}

section.gallery figure .gallery_item-desc span {
    border-radius: 50%;
    display: block;
    height: 50px;
    left: auto;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    top: auto;
    width: 50px;
}

section.gallery figure .gallery_item-desc span::before {
    height: 3px;
    width: 17px;
}

section.gallery figure .gallery_item-desc span::after {
    height: 17px;
    width: 3px;
}

section.gallery figure .gallery_item-desc span::before, section.gallery figure .gallery_item-desc span::after {
    background-color: #ffdc11;
    content: "";
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transition: -webkit-transform 0.25s ease;
    transition: transform 0.25s ease, -webkit-transform 0.25s ease;
    -webkit-transition: -webkit-transform 0.25s ease;
}

section.gallery figure .gallery_item-desc span:hover::before, section.gallery figure .gallery_item-desc span:hover::after {
    transform: translate(-50%, -50%) rotate(90deg);
    -webkit-transform: translate(-50%, -50%) rotate(90deg);
}

section.gallery figure:hover img {
    opacity: 0.2;
}

section.gallery figure:hover .gallery_item-desc {
    opacity: 1;
    transform: scale(1) translateY(-50%);
    -webkit-transform: scale(1) translateY(-50%);
    visibility: visible;
}

@media (max-width: 767px) {
    section.gallery figure .gallery_item-desc h4 {
        display: none;
    }

    section.gallery figure .gallery_item-desc time {
        display: none;
    }
}

@media (max-width: 596px) {
    section.gallery figure {
        width: calc((100% - 4px) / 2);
    }
}

@media (max-width: 400px) {
    section.gallery figure {
        width: 100%;
    }
}