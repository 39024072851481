.clock-promotion {
    background: #8CFF07;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    padding: 15px;
    width: 100%;
}

.clock-promotion .fa {
    float: left;
    font-size: 40px;
    margin-right: 5px;
}

.clock-promotion.clock-inline .fa {
    line-height: 22px;
}

.label-clock-promotion {
    background: #8CFF07;
    border-radius: 0 0 2px 0;
    font-weight: bold;
    padding: 2px 10px;
    position: absolute;
    top: 0;
}
