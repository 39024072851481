.sec-prayer .item-card {
    display: inline-block;
    float: none;
    margin-bottom: 20px;
    margin-left: 15%;
    max-width: 767px;
    text-align: right;
    width: 100%;
}

.sec-prayer .item-card .description {
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    height: 95px;
    padding: 15px;
}

.sec-prayer .item-card .bottom {
    padding: 10px 15px;
}

.sec-prayer .item-card .icon {
    float: left;
    height: 30px;
}

.sec-prayer .item-card.pn-0 {
    margin-left: 0;
    margin-right: 15%;
    text-align: left;
}

.sec-prayer .item-card.pn-0 .icon {
    float: right;
}

.sec-prayer-form {
    background: url(../../images/bg-footer.jpg) center top no-repeat;
    background-size: cover;
    margin: 20px 0;
}

.sec-prayer-form .col-panel {
    padding: 40px;
}

.sec-prayer-form form {
    max-width: 767px;
    width: 100%;
}

.sec-prayer-form form .form-control {
    background: #f8f9fa;
    border-radius: 8px;
}

.txt_palavradia .reference {
    font-weight: 700;
    text-align: left !important;
}

@media (max-width: 767px) {
    .sec-prayer .slider {
        margin: 0 -15px;
        padding: 0 !important;
        width: calc(100% + 30px);
    }

    .sec-prayer .item-card {
        margin: 15px 0 !important;
    }

    .sec-prayer .item-card .description {
        height: auto;
    }

    .sec-prayer-form form {
        margin: 0;
    }

    .slick-list, .slick-track {
        height: auto !important;
    }

    .sec-prayer .btn-prayer {
        line-height: 14px;
        padding: 15px 35px;
    }
}