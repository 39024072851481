.error-404 {
    overflow: hidden;
    padding: 10px 15px 20px 15px;
    position: relative;
    text-align: center;
}

.error-404__figure {
    display: inline-block;
    margin-bottom: 30px;
    max-width: 75%;
    position: relative;
}

.error-404__figure::before, .error-404__figure::after {
    background-color: #ffdc11;
    border-radius: 3px;
    bottom: 0;
    content: "";
    display: block;
    height: 25px;
    position: absolute;
    right: 0;
    width: 120px;
}

.error-404__figure::before {
    transform: translate(50px, -70px) rotate(45deg);
    -webkit-transform: translate(50px, -70px) rotate(45deg);
}

.error-404__figure::after {
    transform: translate(50px, -70px) rotate(-45deg);
    -webkit-transform: translate(50px, -70px) rotate(-45deg);
}

.error__header {
    margin-bottom: 20px;
}

.error__title {
    font-size: 24px;
    margin-bottom: 0.33em;
}

.error__subtitle {
    font-size: 18px;
}

.error__description {
    margin-bottom: 30px;
}

@media (min-width: 1200px) {
    .error__header {
        margin-bottom: 40px;
    }

    .error-404__figure {
        margin-bottom: 55px;
    }

    .error__title {
        font-size: 38px;
    }

    .error__description {
        margin-bottom: 60px;
    }

    .error__subtitle {
        font-size: 22px;
    }
}

@media (min-width: 992px) {
    .error-404 {
        padding: 20px 0 40px 0;
    }

    .error__title {
        font-size: 32px;
    }

    .error__cta .btn {
        margin: 0 10px;
    }
}

@media (max-width: 767px) {
    .error__cta .btn {
        margin: 5px 0;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }
}

@media (min-width: 480px) {
    .error-404__figure {
        max-width: 100%;
    }
}