body {
    background: #fff;
    color: #1c1c1c;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    overflow-x: hidden;
    padding-top: 130px;
}

body.home{
    padding-top: 0;
}

/* Reset
======================= */
a, a:focus, a:hover, button, button:focus, button:hover {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    outline: none;
    text-decoration: none;
}

textarea {
    resize: none;
}
/* *********************** */

/* iCheck
======================= */
.icheckbox_line-blue, .iradio_line-blue {
    background: #94c7e6 !important;
}

.icheckbox_line-blue.checked, .iradio_line-blue.checked {
    background: #2489c5 !important;
}
/* *********************** */

/* Jquery Lazy Config
======================= */
.lazyload {
    background: url('../../images/preloader.gif') no-repeat 50% 50%;
    background-size: 80px 80px;
    width: 100%;
}
/* *********************** */

/* Photoswipe Config
======================= */
.pswp__caption__center {
    text-align: center;
}
/* *********************** */

/* Inputs
======================= */
.form-control, .form-control:focus:not(textarea) {
    height: 48px;
}

.form-control, .form-control:focus {
    border-radius: 0;
    border:1px solid #dfdfdf;
    box-shadow: none;
    width: 100%;
}

.form-control:focus {
    border-color: orange;
}

.has-feedback label ~ .form-control-feedback {
    top: 37px;
}

.input-group {
    width: 100%;
}

.input-group-addon {
    background: #f7f7f7;
    width: 15%;
    max-width: 100px;
    min-width: 70px;
    font-size: 20px;
}
/* *********************** */

/* Select2
======================= */
.select2-container {
    width: 100% !important;
}

.select2-container--default .select2-selection--single {
    background: none;
    border-color: #dfdfdf !important;
    border-radius: 0 !important;
    height: 48px !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 44px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 10px !important;
}
/* ======================== */

.btn-apple-android{
    max-width: 480px;
}

@media (max-width: 1200px) {

}

@media (max-width: 991px) {

}
@media (max-width: 767px) {
    .sec-contacts{
        text-align: center;
    }
}
@media (max-width: 500px) {
    .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11 {
        width: 100%;
    }
}

.sec-palavradia{
    background: url(../../images/bg-palavradia.jpg) center top no-repeat;
    background-size: cover;
    padding: 60px 0;
    margin: 20px 0 0;
}

.a2a_default_style a {
    float: none !important;
    display: inline-block !important;
}

.post-pagination .pagination li a, .post-pagination .pagination li span , .post-pagination .pagination {
    background: #fff !important;
}

.post-pagination .pagination li.active span {
    border-color:  #2D74F2 !important;
    background: #2D74F2 !important;
}