.card {
    background-color: #fff;
    border: 1px solid #e4e7ed;
    border-radius: 8px;
    margin-bottom: 15px;
}

.card .card__header {
    border-bottom: 1px solid #e4e7ed;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
    padding: 18px 23px;
    position: relative;
}

.card .card__header::before {
    background-color: #094730;
    border-radius: 3px 0 0 0;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    width: 6px;
}

.card .card__header > h4 {
    font-size: 16px;
    letter-spacing: -0.02em;
    line-height: 1.2em;
    margin: 0;
    text-transform: uppercase;
}

.card .card__content {
    padding: 24px 24px;
}

@media (min-width: 1199px) {
    .card .card__header .card-header__button {
        float: right;
    }
}