.bg-warning {
    background-color: #ff9d3c;
}

.bg-neutral {
    background-color: #A3A3A3;
}

.bg-success-light {
    background-color: #79CE3D;
}

.bg-success-medium {
    background-color: #01AC66;
}

.bg-success {
    background-color: #00a457;
}

.bg-white {
    background-color: #fff;
}

.bg-black {
    background-color: #1c1c1c;
}

.bg-blue{
    background-color: #0a92e0;
}

.bg-default {
    background-color: #CBCBCB;
}

.bg-primary {
    background-color: #037635;
}

.bg-secundary {
    background-color: #B31D1C;
}

/* Hover
======================= */
.bg-hover-warning:hover {
    background-color: #ff9d3c;
}

.bg-hover-neutral:hover {
    background-color: #A3A3A3;
}

.bg-hover-success-light:hover {
    background-color: #79CE3D;
}

.bg-hover-success-medium:hover {
    background-color: #01AC66;
}

.bg-hover-success:hover {
    background-color: #167A62;
}

.bg-hover-white:hover {
    background-color: #fff;
}

.bg-hover-black:hover {
    background-color: #1c1c1c;
}

.bg-hover-default:hover {
    background-color: #CBCBCB;
}

.bg-hover-primary:hover {
    background-color: #037635;
}

.bg-hover-secundary:hover {
    background-color: #B31D1C;
}