.blog-highlights {
    margin-top: 15px;
}

.blog-highlights .posts__item {
    border-radius: 4px;
    margin-bottom: 15px;
}

.blog-highlights .posts__item:last-child {
    margin-bottom: 0;
}

.blog-highlights .posts__item--card, .blog-highlights .posts__item--card:last-child {
    background-color: #fff;
}

.blog-highlights .posts__item--card .posts__thumb {
    float: none;
    margin: 0;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.blog-highlights .posts__item--card .posts__thumb:first-child {
    border-radius: 4px 4px 0 0;
    overflow: hidden;
}

.blog-highlights .posts__item--card .posts__thumb img {
    height: auto;
    transition: -webkit-transform 0.5s ease;
    -webkit-transition: -webkit-transform 0.5s ease;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.blog-highlights .posts__item--card .posts__cat {
    display: block;
    left: 23px;
    margin-bottom: 8px;
    overflow: hidden;
    position: absolute;
    top: 23px;
    z-index: 1;
}

.blog-highlights .posts__item--card .posts__cat-label {
    background-color: #00993F;
    color: #fff;
    display: block;
    float: left;
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
    padding: .6em 1.1em .5em;
    text-transform: uppercase;
}

.blog-highlights .posts__item--card .posts__cat-label:hover, .blog-highlights .posts__item--card .posts__cat-label:focus {
    color: #fff;
}

.blog-highlights .posts__item--card .posts__date {
    display: inline-block;
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
    line-height: 1.2em;
    text-transform: uppercase;
}

.blog-highlights .posts__item--card .posts__title {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.03em;
    line-height: 1em;
    margin-bottom: 0.2em;
    margin-top: 5px;
    text-transform: uppercase;
}

.blog-highlights .posts__item--card .posts__title a {
    color: #31404b;
    transition: color 0.2s ease-in-out;
    -webkit-transition: color 0.2s ease-in-out;
}

.blog-highlights .posts__item--card .posts__title a:hover {
    color: #4f6779;
}

.blog-highlights .posts__item--card .posts__excerpt {
    min-height: 93px;
    padding: 13px 0 0 0;
}

.blog-highlights .posts__item--card .posts__inner {
    padding: 18px 24px 24px;
    position: relative;
}

.blog-highlights figure .icon {
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 50px !important;
}

@media (min-width: 992px) {
    .blog-highlights .posts__item--card .posts__date + .posts__title {
        margin-top: 10px;
        min-height: 54px;
    }

    .blog-highlights .posts__item--card .posts__date {
        font-size: 11px;
    }

    .blog-highlights .posts__item--card .posts__title--sm {
        font-size: 18px;
    }

    .blog-highlights .posts__item--card .posts__title--sm + .posts__excerpt {
        padding: 10px 0 0 0;
    }
}