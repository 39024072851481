body.body-cart {
    background: #eee;
}

#overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #0009;
    display: block;
    z-index: 1100;
    top: 0;
    color: #fff;
    padding: 20% 0;
}

#overlay h3 {
    color: #fff;
    position: relative;
    padding-left: 50px;
    margin: 0 auto;
    width: 420px;
}

#overlay h3 i {
    font-size: 50px;
    position: absolute;
    top: 4px;
    left: 0;
}

#overlay h3 small {
    color: #fff;
}

#header-cart {
    left: 0;
    min-height: 119px;
    padding: 20px 0;
    position: absolute;
    top: 0;
}

#checkout .block-checkout-steps {
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    margin-top: 15px;
    padding: 20px;
    position: relative;
    top: -6px;
}

@media (max-width: 767px) {
    body.body-cart {
        padding-top: 167px;
    }

    #header-cart {
        padding: 10px 0;
        text-align: center;
    }

    #header-cart .logo {
        display: inline-block;
        margin-bottom: 10px;
    }

    #header-cart .logo img {
        max-width: 180px;
    }
}