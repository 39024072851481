#login-container .btn-facebook {
    background-color: #3B5998;
    border-radius: 4px;
    box-shadow: 0 0 0 0 #e7e7e7 inset;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    padding: 10px 80px;
    position: relative;
    text-align: center;
}

#login-container .btn-facebook i {
    background: #0003;
    font-size: 23px;
    left: 0;
    padding: 10px 25px;
    position: absolute;
    text-align: center;
    top: 0;
}

#login-container .separator {
    display: inline-block;
    margin-bottom: 10px;
    position: relative;
    text-align: center;
    width: 100%;
}

#login-container .separator span {
    background: #E9F4F5;
    font-size: 18px;
    font-weight: 600;
    padding: 0 10px;
    position: relative;
    z-index: 1;
}

#login-container .separator hr {
    border-color: rgba(0,0,0,0.2);
    left: 0;
    position: absolute;
    top: -4px;
    width: 100%;
}