#checkout .checkout-area .checkout-left .panel-group .panel-default .panel-heading {
    background-color: #f8f8f8;
    padding: 0
}

#checkout .checkout-area .checkout-left .panel-group .panel-default .panel-heading .panel-title {
    font-size: 18px;
    padding: 20px 15px
}

#checkout .checkout-area .checkout-left .panel-group .panel-default .panel-heading .panel-title a {
    display: block
}

#checkout .checkout-area .checkout-right {
    display: inline;
    float: left;
    width: 100%
}

#checkout .checkout-area .checkout-right h4 {
    margin-top: 0
}

#checkout .checkout-area .checkout-right .aa-order-summary-area {
    margin-bottom: 30px
}

#checkout .checkout-area .checkout-right .aa-order-summary-area .table {
    border: 1px solid #ccc
}

#checkout .checkout-area .checkout-right .aa-order-summary-area .table thead tr th {
    border: 1px solid #ccc;
    text-align: center
}

#checkout .checkout-area .checkout-right .aa-order-summary-area .table tbody tr td {
    border-right: 1px solid #ccc;
    text-align: center
}

#checkout .checkout-area .checkout-right .aa-order-summary-area .table tbody tr td strong {
    font-size: 14px;
    margin-left: 5px
}

#checkout .checkout-area .checkout-right .aa-payment-method {
    border: 1px solid #ccc;
    padding: 15px
}

#checkout .checkout-area .checkout-right .aa-payment-method label {
    font-weight: normal;
    width: 100%
}

#checkout .checkout-area .checkout-right .aa-payment-method img {
    margin-top: -10px;
    width: 80%
}

#checkout .checkout-area .checkout-right .aa-payment-method input[type="submit"] {
    display: block;
    margin-top: 15px;
    padding: 8px 12px;
    width: 100%
}

#checkout .checkout-area .checkout-left .panel-group .form-group {
    min-height: 48px;
}

#checkout .checkout-area .checkout-left .panel-group .form-group .help-block {
    font-size: 12px;
    margin: 0;
    position: absolute;
    right: 5px;
    top: 30px;
    z-index: 2;
}

#checkout .checkout-area .checkout-left .panel-group .aa-checkout-login button {
    border-radius: 0;
    padding: 13px 15px;
}
