.content-container .post--single .post__thumbnail {
    border-radius: 4px 4px 0 0;
    overflow: hidden;
}

.content-container .post--single .post__thumbnail img {
    width: 100%;
}

.content-container .post-author .post-author__header {
    overflow: hidden;
}

.content-container .post-author .post-author__description {
    margin-top: 20px;
}

.content-container .post-author figure {
    border-radius: 50%;
    display: inline-block;
    height: 60px;
    margin: 0 14px 0 0;
    overflow: hidden;
    vertical-align: middle;
    width: 60px;
}

.content-container .post-author figure img {
    height: auto;
    max-width: 100%;
}

.content-container .post-author .post-author__info {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.content-container .post-author .post-author__info span {
    color: #9a9da2;
    display: block;
    font-size: 14px;
    font-family: 'montserratregular', serif;
    line-height: 1.2em;
}

.content-container .post-author .post-author__info h4 {
    margin-bottom: .2em;
}

.content-container .post-author ul {
    list-style-type: none;
    margin: 0;
    padding: 10px 0 0 0;
}

.content-container .post-author ul li {
    display: inline-block;
    font-size: 9px;
    margin: 0;
    text-transform: uppercase;
}

.content-container .post-author ul li .fa {
    color: #fff;
    font-size: 12px;
    transition: color 0.2s ease;
    -webkit-transition: color 0.2s ease;
}

.content-container .post-author ul li:last-child {
    margin-right: 0;
}

.content-container .post-author ul a {
    border-radius: 2px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    height: 26px;
    line-height: 26px;
    margin: 0 2px;
    text-align: center;
    transition: opacity 0.2s ease-in-out;
    -webkit-transition: opacity 0.2s ease-in-out;
    width: 26px;
}

.content-container .post-author ul li a:hover {
    opacity: 0.8;
}

.content-container .post-author ul li a:hover .fa {
    color: #fff;
}

.content-container .post-author ul li a.social-facebook {
    background-color: #4d6baa;
}

.content-container .post-author ul li a.social-twitter {
    background-color: #3fcef2;
}

.content-container .post-author ul li a.social-instagram {
    background-color: #f26d35;
}

.content-container .post-author ul li a.social-gplus {
    background-color: #e34d39;
}

.content-container .post__category {
    margin-bottom: 15px;
    overflow: hidden;
}

.content-container .post__category .label {
    background-color: #010302;
    display: block;
    float: left;
}

.content-container header .post__title {
    color: #011820;
    font-size: 18px;
    line-height: 1.0625em;
    margin-bottom: 0.55em;
}

.content-container header ul {
    list-style: none;
    margin-bottom: 20px;
    padding: 0;
}

.content-container header li {
    display: inline-block;
    font-size: 11px;
    line-height: 1.2em;
    text-transform: uppercase;
    vertical-align: middle;
}

.content-container .post__content {
    color: #1c1c2b;
}

.content-container .post__content h2 {
    font-size: 20px;
}

.content-container .post__content h3 {
    font-size: 18px;
}

.content-container .post__content h4 {
    font-size: 16px;
}

.content-container .post__content table {
    border-collapse: inherit;
    border-spacing: 15px;
}

.content-container .post__content img {
    height: auto !important;
    max-width: 100%;
}

.content-container .post__footer {
    padding: 30px 0 10px 0;
}

.content-container .post__footer .post__tags .btn {
    margin: 0 5px 8px 0;
}

@media (min-width: 992px) {
    .content-container .post-author ul {
        float: right;
        margin-right: -2px;
    }

    .content-container header .post__title {
        font-size: 32px;
    }

    .content-container header ul {
        margin-bottom: 34px;
    }
}