#products-sidebar aside:not(#products-sidebar aside:first-child) {
    border-top: 1px solid #ccc;
}

#products-sidebar aside h3 {
    border-bottom: 1px solid #1c1c1c;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

#products-sidebar .navigation {
    display: inline-block;
    width: 100%;
}

.mainmenu, .mainmenu .submenu, .mainmenu .third {
    list-style: none;
    margin: 0;
    padding: 0 !important;
}

.mainmenu a {
    color: #000;
    display: inline-block;
    padding: 10px 5px;
    text-decoration: none;
    width: calc(100% - 40px);
}

.mainmenu li:first-child {
    border-top: none;
}

.mainmenu li {
    border-bottom: 1px solid #e0e0e0;
}

.mainmenu li a:hover, .mainmenu li.active > a {
    color: #00a457;
}

.mainmenu li:hover > .toggle-submenu {
    background: #00a457;
    border-color: #00a457;
}

.mainmenu .toggle-submenu {
    float: right;
    padding: .2em .6em .3em;
    position: relative;
    top: 6px;
}

.mainmenu .toggle-submenu i {
    position: relative;
    top: 1px;
}

.mainmenu .submenu, .mainmenu .third {
    max-height: 0;
    overflow: hidden;
    transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    -webkit-transition: all .5s;
}

.mainmenu .submenu, .mainmenu .third {
    padding: 0 0 0 15px !important;
    width: calc(100% - 15px);
}

.mainmenu .submenu a {
    color: #666;
}

.mainmenu .submenu.open, .third.open {
    display: block;
    max-height: 2000px;
}

.mainmenu .submenu li:before {
    color: #666;
    content: "\f0da";
    font-family: FontAwesome, serif;
    font-size: 10px;
}