.contents-container {
    padding: 20px 0;
}

.contents-container .content-item {
    background-color: #fff;
    border: 1px solid #e4e7ed;
    border-radius: 4px;
    margin-bottom: 15px;
}

.contents-container .content-item > figure {
    border-radius: 4px 4px 0 0;
    float: none;
    margin: 0;
    max-height: 250px;
    min-height: 250px;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.contents-container .content-item > figure > a {
    color: #fff;
    font-family: 'montserratmedium', serif;
    text-align: center;
}

.contents-container .content-item > figure > div {
    display: block;
    left: 23px;
    margin-bottom: 8px;
    overflow: hidden;
    position: absolute;
    top: 23px;
    z-index: 1;
}

.contents-container .content-item > figure .label {
    background-color: #0c45af;
    border-radius: .25em;
    color: #fff;
    display: block;
    float: left;
    font-size: 10px;
    font-family: "Montserrat", sans-serif;
    line-height: 1;
    padding: .6em;
    text-align: center;
    text-transform: uppercase;
    vertical-align: baseline;
    white-space: nowrap;
}

.contents-container .content-item > figure img {
    display: inline-block;
    height: 250px;
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    -moz-transition: transform 0.5s ease;
    -o-transition: transform 0.5s ease;
    -webkit-transition: -webkit-transform 0.5s ease;
    -webkit-transition: transform 0.5s ease;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    object-fit: cover;
}

.contents-container .content-item > figure img:hover {
    transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    -webkit-transform: scale(1.1);
}

.contents-container .content-item .content-item_info {
    padding: 18px 24px 24px;
    position: relative;
}

.contents-container .content-item .content-item_info > a {
    background-color: #0c45af;
    border-radius: 50%;
    display: block;
    height: 50px;
    overflow: hidden;
    position: absolute;
    right: 23px;
    top: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    width: 50px;
}

.contents-container .content-item .content-item_info > a:before {
    height: 3px;
    width: 17px;
}

.contents-container .content-item .content-item_info > a:after {
    height: 17px;
    width: 3px;
}

.contents-container .content-item .content-item_info > a:hover:before, .contents-container .content-item .content-item_info > a:hover:after {
    transform: translate(-50%, -50%) rotate(90deg);
    -webkit-transform: translate(-50%, -50%) rotate(90deg);
}

.contents-container .content-item .content-item_info > a:before, .contents-container .content-item .content-item_info > a:after {
    background-color: #fff;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transition: -webkit-transform 0.25s ease;
    transition: transform 0.25s ease;
    -moz-transition: transform 0.25s ease;
    -o-transition: transform 0.25s ease;
    -webkit-transition: -webkit-transform 0.25s ease;
    -webkit-transition: transform 0.25s ease;
}

.contents-container .content-item .content-item_info > time, .contents-container .content-item .content-item_info > span {
    display: inline-block;
    font-size: 11px;
    line-height: 1.2em;
    text-transform: uppercase;
}

.contents-container .content-item .content-item_info > h6 {
    font-size: 23px;
    letter-spacing: -0.03em;
    line-height: 1.2em;
    margin-bottom: 0.8em;
    margin-top: 10px;
    min-height: 46px;
    text-transform: uppercase;
}

.contents-container .content-item .content-item_info > .content-item_description {
    min-height: 108px;
}

.contents-container .content-item footer {
    border-top: 1px solid #e4e7ed;
    display: table;
    height: 100%;
    padding: 17px 24px;
    width: 100%;
}

.contents-container .content-item .content-item_author {
    display: table-cell;
    text-align: left;
    width: 50%;
}

.contents-container .content-item .content-item_author > figure {
    border-radius: 50%;
    display: inline-block;
    height: 24px;
    margin: 0 14px 0 0;
    overflow: hidden;
    vertical-align: middle;
    width: 24px;
}

.contents-container .content-item .content-item_author img {
    height: auto;
    max-width: 100%;
    vertical-align: top;
}

.contents-container .content-item .content-item_author-info {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.contents-container .content-item .content-item_author-info h4 {
    color: #9a9da2;
    font-family: 'montserratregular', serif;
    font-size: 10px;
    margin-bottom: 0.2em;
    margin-top: 10px;
    text-transform: uppercase;
}

@media (max-width: 767px) {

    .contents-container .content-item > figure,
    .contents-container .content-item > figure img {
        height: auto;
        min-height: auto;
    }

    .contents-container .content-item .content-item_info > .content-item_description {
        min-height: auto;
    }
}
