#share-dayword {
    background: url("../../images/bg-footer.jpg") no-repeat top center;
    color: #fff;
    font-family: 'Times New Roman', sans-serif;
    height: 100%;
    left: 0;
    padding-top: 50px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 101;
}

#share-dayword h2 {
    color: #fff;
    font-size: 32px;
    margin: 20px 0;
}

#share-dayword p {
    font-size: 25px;
    line-height: 30px;
}

#share-dayword small {
    font-size: 20px;
    font-weight: bold;
}