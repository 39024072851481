.products-highlights {
    padding: 20px 0 0;
}

.products-highlights .products-highlights__item {
    margin-bottom: 20px;
}

.products-highlights .product-featured {
    background: #fff;
    border-radius: 4px;
    display: inline-block;
    overflow: hidden;
}

.product-featured .product-featured__thumb {
    background: #000;
    display: inline-block;
    height: 262px;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.product-featured .product-featured__thumb .aa-badge {
    border-radius: 0 0 4px 0;
    color: #fff;
    font-size: 15px;
    left: 0;
    padding: 5px 10px;
    position: absolute;
    top: 0;
    z-index: 2
}

.product-featured .product-featured__thumb .aa-badge.promotion {
    border-radius: 4px 0 0 0;
    bottom: 0;
    left: auto;
    padding: 5px 15px;
    right: 0;
    top: auto;
}

.product-featured .product-featured__thumb .aa-sale {
    background-color: #6cc506;
}

.product-featured .product-featured__thumb .aa-sold-out {
    background-color: #f00;
}

.product-featured .product-featured__thumb .aa-hot {
    background-color: #f60;
}

.product-featured .product-featured__thumb .see-more, .product-featured .product-featured__thumb .cart-add, .product-featured .product-featured__thumb .budget-request {
    color: #fff;
    display: inline-block;
    font-weight: bold;
    max-width: 222px;
    padding: 10px;

    position: absolute;
    right: calc(50% - 111px);
    text-align: center;
    text-transform: uppercase;
    width: calc(100% - 20px);
}

.product-featured .product-featured__thumb .see-more {
    background: #5bc0de80;
    border: 1px solid #46b8da;
    top: -70px;
}

.product-featured .product-featured__thumb .cart-add {
    background: #34b13480;
    border: 1px solid #4cae4c;
    top: 327px;
}

.product-featured .product-featured__thumb .budget-request {
    background: #34b13480;
    border: 1px solid #4cae4c;
    top: 327px;
}

.product-featured .product-featured__thumb .budget-request.informa {
    background: rgba(113, 113, 113, 0.76);
    border: 1px solid #000;
}

.product-featured:hover .product-featured__thumb .see-more {
    top: 84px;
}

.product-featured:hover .product-featured__thumb .cart-add {
    top: 136px;
}

.product-featured:hover .product-featured__thumb .budget-request {
    top: 136px;
}

.product-featured .product-featured__thumb .see-more:hover {
    background: #5bc0decc;
}

.product-featured .product-featured__thumb .cart-add:hover {
    background: #34b134cc;
}

.product-featured .product-featured__thumb img {
    height: 262px;
    width: 100%;
    object-fit: cover;
}

.product-featured .product-featured__title {
    display: inline-block;
    min-height: 72px;
    padding: 10px 15px;
}

.product-featured .product-featured__title h6 {
    font-weight: normal;
    line-height: 13px;
    margin: 0;
}

.product-featured .cback-info {
    background: #01A856;
    color: #fff;
    font-weight: 700;
    padding: 2px 0;
    text-align: center;
    text-transform: uppercase;
}

.product-featured .cback-info span {
}

.product-featured .cback-info .fa {
    color: #FCD73E;
}

.product-featured .product-featured__price {
    line-height: 18px;
    padding: 0 15px 10px;
}

.product-featured .product-featured__price p {
    color: #1e48a8;
    font-size: 18px;
    font-weight: bold;
    margin: 2px 0 0;
}

@media (max-width: 583px) {
    .products-highlights .products-highlights__item {
        text-align: center;
        width: 100%;
    }

    .products-highlights .product-featured {
        width: 262px;
    }
}